<template>
  <div>
    <Form ref="form_measure" @saved="reloadMeasure" />
    <div
      @click="addMeasure"
      class="add-button mt-1 text-primary d-flex align-items-center"
    >
      <span class="material-icons mr-2">add</span> Nova Meta
    </div>
    <b-card
      v-for="(measure, index) in measures"
      :key="index"
      no-body
      class="mb-3"
    >
      <card-content-measure
        :name="measure.name"
        :classification="measure.classification"
        :metric-calc="measure.metric.calc"
        :description="measure.description"
        @delete-item="deleteItem(measure)"
        @edit-item="editItem(measure)"
      />
      <template v-if="measure.targets.length > 0">
        <div class="card-body d-flex pt-0">
          <h4 class="mr-auto h5">Datas de Interesse</h4>

          <div class="row">
            <small class="text-muted mr-2">Mostrar datas de interesse</small>
            <collapse-button :id="'tree-item-' + index" />
          </div>
        </div>
        <b-collapse :id="'tree-item-' + index">
          <b-table
            striped
            hover
            :items="measure.targets"
            :fields="fields"
            sort-icon-left
          >
            <template #cell(deadline)="row">
              {{ row.item.deadline | format_date }}
            </template>
            <template #cell(reference_date)="row">
              {{ row.item.reference_date | format_date }}
            </template>
            <template #cell(values)="row">
              <b-list-group class="list-valores-targets">
                <b-list-group-item
                  v-for="(value, key) in row.item.value"
                  :key="key"
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>
                    {{ value.operator }}
                    {{ value.metric }}
                  </span>
                  <font-awesome-icon
                    :icon="['fas', 'circle']"
                    :style="{ color: value.color }"
                  />
                </b-list-group-item>
              </b-list-group>
            </template>
          </b-table>
        </b-collapse>
      </template>
    </b-card>
  </div>
</template>

<script>
import Form from "@/modules/measures/components/Form.vue";
import CardContentMeasure from "@/modules/objectives/components/Card/CardContentMeasure.vue";
import CollapseButton from "./CollapseButton.vue";
import { mapActions, mapState } from "vuex";
import { findIndex } from "lodash";

export default {
  components: { Form, CollapseButton, CardContentMeasure },
  props: {},
  data: () => ({
    expanded: false,
  }),
  computed: {
    ...mapState("objectives", ["objective"]),
    measures() {
      return this.objective.measures;
    },
    fields() {
      return [
        { key: "deadline", label: "Prazo", sortable: true },
        { key: "reference_date", label: "Referencia" },
        { key: "values", label: "Valores" },
      ];
    },
  },
  methods: {
    ...mapActions("measures", ["ActionDelete"]),
    async deleteItem(measure) {
      let index = findIndex(this.objective.measures, ["id", measure.id]);
      let measures = JSON.parse(JSON.stringify(this.objective.measures));
      await this.ActionDelete(measure);

      measures.splice(index, 1);
      this.objective.measures = measures;
    },
    editItem(measure) {
      this.$refs["form_measure"].show(measure.id);
    },
    reloadMeasure(measure) {
      let index = findIndex(this.objective.measures, ["id", measure.id]);
      let measures = JSON.parse(JSON.stringify(this.objective.measures));
      if (index >= 0) {
        measures[index] = measure;
      } else {
        measures.push(measure);
      }
      this.objective.measures = measures;
    },
    addMeasure() {
      this.$refs["form_measure"].show(null);
    },
  },
  async created() {},
};
</script>

<style lang="scss">
.icon-structure {
  font-size: 2em;
}

.card-header {
  .bt-action {
    opacity: 0;
    transition: all 0.3s;

    &:first-of-type {
      margin-left: auto !important;
    }
  }

  &:hover {
    .bt-action {
      opacity: 1;
    }
  }
}

.list-valores-targets {
  .list-group-item {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }
}
</style>
