<template>
  <b-modal
    ref="modal-add-indicador"
    v-model="modalShow"
    title="Adicionar um novo Indicador"
    size="lg"
    ok-title="Adicionar"
    @ok="submit"
  >
    <b-form-group label="Base de Indicadores:">
      <b-input-group>
        <b-form-select v-model="api_selected" class="mb-3">
          <b-form-select-option
            v-for="(value, key) in api_indicadores"
            :key="key"
            :value="value.id"
            >{{ value.label }}</b-form-select-option
          >
        </b-form-select>

        <b-form-input
          v-model="api_sigla_indicador"
          placeholder="pesquisar"
        ></b-form-input>
        <b-input-group-append>
          <b-button variant="outline-info" @click="buscarIndicador">
            <font-awesome-icon
              :icon="['fas', 'circle-notch']"
              v-if="checking"
              spin
            />
            <font-awesome-icon
              :icon="['fas', 'check-square']"
              v-if="!checking"
            />

            Buscar</b-button
          >
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <div v-if="isLoading" class="text-center m-4">
      <b-spinner label="Loading..." variant="info"></b-spinner>
    </div>

    <b-alert variant="danger" :show="verify_error"
      >Indicador não encontrado</b-alert
    >

    <div v-if="show_list_indicadores">
      <SelectIndicadores
        @selected="verifyIndicator"
        :data_indicadores="data_indicadores"
      />
    </div>

    <div v-if="indicator && indicator.url">
      <b-form-group label="Nome:">
        <b-form-input
          v-model="indicator.name"
          placeholder="Nome"
          :state="validateState('name')"
        ></b-form-input>
        <b-form-invalid-feedback
          >Nome é um campo obrigatório.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Sigla:">
        <b-form-input
          v-model="indicator.sigla"
          readonly
          placeholder="Sigla"
          :state="validateState('sigla')"
        ></b-form-input>
        <b-form-invalid-feedback
          >Sigla é um campo obrigatório.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Descrição:">
        <b-form-input
          v-model="indicator.description"
          placeholder="Descrição"
        ></b-form-input>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { filter } from "lodash";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import SelectIndicadores from "./SelectIndicadores";

export default {
  components: { SelectIndicadores },
  mixins: [validationMixin],
  data: () => ({
    checking: false,
    isLoading: false,
    indicator: {
      name: "",
      description: "",
      url: "",
      sigla: "",
    },
    verify_error: null,
    api_selected: null,
    api_sigla_indicador: null,
    api_indicadores: [
      {
        id: "api_munindicadores",
        label: "Indicadores Primários",
        url: process.env.VUE_APP_ROOT_API + "api/municipality-indicators/",
      },
      {
        id: "api_bagindicadores",
        label: "Indicadores Secundários",
        url: "http://indicadores.bagsistemas.com.br/api/indicador/",
      },
    ],
    data_indicadores: {},
    show_list_indicadores: false,
    modalShow: false,
  }),
  validations: {
    indicator: {
      name: {
        required,
      },
      url: {
        required,
      },
      sigla: {
        required,
      },
    },
  },
  computed: {
    ...mapState("objectives", ["objective"]),
    url_indicador() {
      var selected = filter(this.api_indicadores, { id: this.api_selected });

      if (selected[0] && this.api_sigla_indicador) {
        return selected[0].url + this.api_sigla_indicador;
      } else {
        return null;
      }
    },
    url_find_indicador() {
      var selected = filter(this.api_indicadores, { id: this.api_selected });

      if (selected[0] && this.api_sigla_indicador) {
        return selected[0].url + "?q=" + this.api_sigla_indicador;
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions("objectives", [
      "ActionBuscarIndicator",
      "ActionVerifyIndicator",
    ]),
    ...mapActions("indicators", ["ActionFind"]),

    async buscarIndicador() {
      this.indicator = {};
      this.isLoading = true;
      this.show_list_indicadores = false;

      if (this.url_indicador) {
        if (this.api_selected == "api_munindicadores") {
          this.data_indicadores = await this.ActionFind({
            q: this.api_sigla_indicador,
          });
        } else {
          this.data_indicadores = await this.ActionVerifyIndicator(
            this.url_find_indicador
          );
        }

        this.isLoading = false;
        this.show_list_indicadores = true;
      }
      this.isLoading = false;
    },
    async verifyIndicator(indicador) {
      this.show_list_indicadores = false;
      this.isLoading = true;

      this.api_sigla_indicador = indicador.sigla;
      this.checking = true;
      this.verify_error = false;

      this.indicator.name = indicador.nome;
      this.indicator.sigla = indicador.sigla;
      this.indicator.url = this.url_indicador;

      this.checking = false;
      this.isLoading = false;
    },
    submit(evt) {
      evt.preventDefault();

      this.$v.indicator.$touch();
      if (!this.$v.indicator.$anyError) {
        this.objective.indicators.push(
          JSON.parse(JSON.stringify(this.indicator))
        );
        this.indicator = null;
        this.modalShow = false;
      }
    },
    show() {
      this.modalShow = true;
      this.indicator = {
        name: "",
        description: "",
        url: "",
        sigla: "",
      };

      if (this.$v.item) {
        this.$v.item.$reset();
      }
      this.show_list_indicadores = false;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.indicator[name];
      return $dirty ? !$error : null;
    },
  },
};
</script>

<style>
</style>
