<template>
  <b-table small striped hover show-empty :items="indicadores" :fields="fields">
    <template #empty="{}">
      <h6 class="text-center">Nenhum indicador encontrado</h6>
    </template>

    <template #cell(actions)="row">
      <div class="text-nowrap">
        <b-button size="sm" variant="info" @click="selecionar(row.item)"
          >Selecionar</b-button
        >
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  props: {
    data_indicadores: {
      type: Object,
    },
  },
  computed: {
    indicadores() {
      return this.data_indicadores.data;
    },
    fields() {
      return [
        { key: "id", label: "#" },
        { key: "nome", label: "Indicador" },
        { key: "sigla", label: "Sigla" },
        { key: "actions", label: "#" },
      ];
    },
  },
  methods: {
    selecionar(indicador) {
      this.$emit("selected", indicador);
    },
  },
};
</script>

<style>
</style>
