<template>
  <div class="w-100 mb-5">
    <Header />

    <b-form @submit="onSubmit">
      <b-card
        no-body
        v-if="objective"
        class="radius-top-0 border-0"
        header-class="bg-white border-top border-bottom-0"
      >
        <template #header>
          <div class="d-flex justify-content-between align-items-center">
            <b class="text-secondary">Editando Objetivo</b>
            <div class="d-flex flex-row-reverse">
              <b-button type="submit" variant="primary">Salvar</b-button>

              <b-button
                :to="{
                  name: 'strategic_plans_show'
                }"
                variant="outline-secondary"
                class="mr-2"
              >
                Cancelar
              </b-button>
            </div>
          </div>
        </template>

        <b-tabs pills card vertical class="edit-menu-sidebar">
          <b-tab title="Dados Básicos" active>
            <DadosBasicos />
          </b-tab>
          <b-tab title="ODS"><Ods /></b-tab>
          <b-tab title="Indicadores">
            <Indicadores />
          </b-tab>
          <b-tab title="Metas">
            <Metas />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-form>
  </div>
</template>

<script>
import Header from "../components/Header";
import DadosBasicos from "../components/Form/DadosBasicos";
import Indicadores from "../components/Form/Indicadores";
import Metas from "../components/Form/Metas";

import Ods from "../components/Form/Ods";
import { mapState, mapActions } from "vuex";

export default {
  components: { Header, DadosBasicos, Ods, Indicadores, Metas },
  computed: {
    ...mapState("objectives", ["objective"])
  },
  methods: {
    ...mapActions("objectives", ["ActionShow", "ActionSave"]),
    async onSubmit(event) {
      event.preventDefault();
      await this.ActionSave(this.objective);
      this.loadData(this.objective.id);
      this.$notifications.addNotification({
        message: "Atualizado com sucesso!",
        type: "success"
      });
    },
    async loadData(id) {
      await this.ActionShow({ id: id });
    }
  },
  async created() {
    await this.loadData(this.$route.params.id);
  }
};
</script>

<style></style>
