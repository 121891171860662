<template>
  <b-modal
    ref="modal-form-measure"
    :title="name"
    size="xl"
    :ok-title="ok_title"
    cancel-title="Cancelar"
    @ok.prevent="submit"
    v-if="measure"
  >
    <template #modal-footer="{ ok, cancel }">
      <select-type-measure v-model="measure.classification" class="mr-auto" />
      <b-button variant="secondary" @click="cancel"> Cancelar </b-button>
      <b-button variant="primary" @click="ok">
        {{ ok_title }}
      </b-button>
    </template>
    <b-tabs card>
      <b-tab title="Dados Básicos" active>
        <b-form-group label="Nome:">
          <b-form-input
            v-model="measure.name"
            placeholder="Nome"
            :state="validateState('name')"
          />
          <b-form-invalid-feedback>
            Nome é um campo obrigatório.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Descrição:">
          <b-form-textarea
            v-model="measure.description"
            placeholder="Descrição"
            class="max-height-textarea"
          />
        </b-form-group>

        <b-row>
          <b-col cols="8">
            <b-form-group label="Fórmula:">
              <b-form-input
                v-model="measure.metric.calc"
                placeholder="Fórmula"
                :state="validateState('metric.calc')"
              />
              <b-form-invalid-feedback>
                Fórmula da meta é um campo obrigatório.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Casas Decimais:">
              <b-form-select
                v-model="measure.metric.decimals"
                :options="[0, 1, 2, 3, 4, 5, 6]"
                :state="validateState('metric.decimals')"
              />
              <b-form-invalid-feedback>
                Casas decimais do gráfico é um campo obrigatório.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="Label do Gráfico:">
          <b-form-input
            v-model="measure.metric.label"
            placeholder="Label do Gráfico"
            :state="validateState('metric.label')"
          />
          <b-form-invalid-feedback>
            Label é um campo obrigatório.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Ínicio e Fim:">
          <b-row>
            <b-col>
              <date-picker v-model="measure.metric.start_in" class="mb-3" />
            </b-col>
            <b-col>
              <date-picker v-model="measure.metric.end_in" class="mb-3" />
            </b-col>
          </b-row>
        </b-form-group>
      </b-tab>
      <b-tab title="Limites">
        <limites />
      </b-tab>
      <b-tab title="Outros valores">
        <table-new-value
          ref="tableOtherMetrics"
          v-model="measure.other_metrics"
        />
      </b-tab>
      <b-tab title="Configurações do Gráfico">
        <b-form-group label="Fonte dos dados">
          <b-form-input
            v-model="measure.source"
            placeholder="Ex: IBGE, DATASUS"
            :state="validateState('name')"
          />
          <b-form-invalid-feedback>
            Nome é um campo obrigatório.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Exibir valores:">
          <b-form-checkbox v-model="measure.chart_config.label_show">
            Os valores serão exibidos no gráfico
          </b-form-checkbox>
        </b-form-group>

        <b-form-group label="Posição dos valores:">
          <input-chip
            v-model="measure.chart_config.label_position"
            :options="label_position_options"
            hint="Essa posição será utilizada na exibição das legendas nos gráficos."
          />
        </b-form-group>

        <b-form-group label="Ocultar último valor do limite :">
          <b-form-checkbox v-model="measure.chart_config.limit_hide_last">
            Ocultar o último valor dos limites
          </b-form-checkbox>
        </b-form-group>
      </b-tab>

      <b-tab title="Configurações da Tabela">
        <b-form-group
          label="Ocultar valores de metas, apresentando apenas legenda com cor."
        >
          <input-chip
            v-model="measure.chart_config.show_table_legend"
            :options="tableLegendShow"
          />
        </b-form-group>

        <b-form-group label="Periodicidade em">
          <input-chip
            v-model="measure.chart_config.show_table_frequency"
            :options="frequencyTable"
          />
        </b-form-group>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { mapActions, mapState } from "vuex";
import Limites from "./Form/Limites.vue";
import InputChip from "./InputChip.vue";
import SelectTypeMeasure from "@/modules/measures/components/SelectTypeMeasure.vue";
import TableNewValue from "@/modules/measures/components/TableNewValue.vue";

export default {
  components: { Limites, InputChip, SelectTypeMeasure, TableNewValue },
  mixins: [validationMixin],
  data: () => ({
    checking: false,
    isLoading: true,
    renderTargets: true,
  }),
  validations: {
    measure: {
      name: {
        required,
      },
      metric: {
        label: {
          required,
        },
        calc: {
          required,
        },
        decimals: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapState("objectives", ["objective"]),
    ...mapState("measures", ["measure"]),
    name() {
      const hasClassification = this.measure.classification;
      const isMeasure = this.measure.classification === "goal";
      const titleOnMeasure = isMeasure ? "meta" : "indicador";
      return this.measure.name
        ? this.measure.name
        : hasClassification
        ? `Adicionar ${titleOnMeasure}`
        : "Adicionar meta";
    },
    target_fields() {
      return [
        {
          key: "label",
          label: "Label",
        },
        {
          key: "metric",
          label: "Fórmula",
        },
        {
          key: "operator",
          label: "Operador",
        },
        {
          key: "color",
          label: "Cor",
        },
        {
          key: "action",
          label: "#",
        },
      ];
    },
    color_list() {
      return ["#FF0000", "#00FF00", "#0000FF", "#FFFF24"];
    },
    operadores() {
      return [
        { value: "==", text: "=" },
        { value: ">", text: ">" },
        { value: ">=", text: ">=" },
        { value: "<", text: "<" },
        { value: "<=", text: "<=" },
      ];
    },
    ok_title() {
      return this.measure.id ? "Salvar" : "Adicionar";
    },
    label_position_options() {
      return [
        { value: "top", text: "Acima", icon: "arrow-up" },
        { value: "left", text: "Esquerda", icon: "arrow-left" },
        { value: "right", text: "Direita", icon: "arrow-right" },
        { value: "bottom", text: "Abaixo", icon: "arrow-down" },
        { value: "inside", text: "Dentro" },
      ];
    },
    frequencyTable() {
      const buildItem = (value, text, icon) => ({ value, text, icon });
      return [
        buildItem("linhas", "Linhas", "grip-lines"),
        buildItem("colunas", "Colunas", "columns"),
      ];
    },
    tableLegendShow() {
      const buildItem = (value, text, icon) => ({ value, text, icon });
      return [buildItem(true, "Sim"), buildItem(false, "Não")];
    },
  },
  methods: {
    ...mapActions("measures", ["ActionSave", "ActionShow", "ActionNew"]),
    async submit() {
      this.$v.measure.$touch();
      if (!this.$refs.tableOtherMetrics.isValid()) {
        this.$notifications.addNotification({
          message: "Os outros valores adicionados são inválidos",
          type: "danger",
        });
        return;
      }

      if (this.$v.measure.$anyError) {
        this.$notifications.addNotification({
          message: "Os dados do formulário são inválidos",
          type: "danger",
        });
        return;
      }
      try {
        this.measure.objective_id = this.objective.id;
        let measure = await this.ActionSave(this.measure);
        this.$notifications.addNotification({
          message: "Registro salvo com sucesso",
          type: "success",
        });
        this.$emit("saved", measure);
        this.$refs["modal-form-measure"].hide();
      } catch (error) {
        console.log("ERROR", error);
      }
    },
    validateState(name) {
      let indexes = name.split(".");
      let validations = this.$v.measure;
      for (let i in indexes) {
        validations = validations[indexes[i]];
      }
      const { $dirty, $error } = validations;
      return $dirty ? !$error : null;
    },
    async show(measure_id) {
      this.isLoading = true;
      this.$v.measure.$reset();
      this.ActionNew();
      if (measure_id) {
        await this.ActionShow(measure_id);
      }
      this.$refs["modal-form-measure"].show();
      this.isLoading = false;
    },
    addTarget() {
      this.measure.targets.push({
        deadline: "",
        reference_date: "",
        value: [],
      });
    },
    addTargetValue(index) {
      this.measure.targets[index].value.push({
        color: "#F0F0F0",
        label: "",
        metric: "",
        operator: ">",
      });
    },
    delete_limite(index) {
      this.isLoading = true;
      this.measure.targets.splice(index, 1);
      this.forcerRenderTargets();
      this.isLoading = false;
    },
    delete_valor_limite(target, index) {
      this.measure.targets[target].value.splice(index, 1);
    },
    forcerRenderTargets() {
      this.renderTargets = false;
      this.$nextTick(() => {
        this.renderTargets = true;
      });
    },
  },
  created() {
    this.ActionNew();
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.delete_limite {
  right: 0;
  margin-top: -15px;
}
.max-height-textarea {
  max-height: 100px;
  min-height: 3em;
}
</style>
