<template>
  <div class="row no-gutters">
    <div
      v-for="(option, key) in options"
      :key="'selectable_option_' + key"
      class="col-auto mr-2"
    >
      <b-btn
        :variant="getActiveColor(option.value)"
        @click="$emit('change', option.value)"
        :disabled="option.disabled"
        size="sm"
        pill
      >
        <slot name="label" :option="option">
          <span class="mr-1" v-if="!!option.icon">
            <font-awesome-icon :icon="option.icon" />
          </span>
          {{ option.text }}
        </slot>
      </b-btn>
    </div>
    <small v-if="!!hint" class="text-secondary mt-2 w-100">{{ hint }}</small>
  </div>
</template>
<script>
export default {
  name: "InputChip",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    hint: {
      type: String,
      default: "",
    },
  },
  methods: {
    getActiveColor(value) {
      return this.value === value ? "primary" : "outline-secondary";
    },
  },
};
</script>
