<template>
  <div class="row no-gutters">
    <b-button variant="primary" size="sm" pill class="ml-auto" @click="addLine">
      <b-icon icon="plus" />
      Adicionar valor
    </b-button>
    <b-table
      :items="modelValues"
      :fields="fields"
      small
      show-empty
      class="mt-3"
    >
      <template #cell(label)="{ item, index }">
        <b-form-input
          v-model="item.label"
          placeholder="Label do gráfico"
          :state="validateState('label', index)"
        />
      </template>
      <template #cell(calc)="{ item, index }">
        <b-form-input
          v-model="item.calc"
          placeholder="Fórmula"
          :state="validateState('calc', index)"
        />
      </template>
      <template #cell(action)="{ index }">
        <b-button size="sm" variant="danger" @click="deleteLine(index)">
          <b-icon icon="trash" />
        </b-button>
      </template>
      <template #empty>
        <b-card class="border-0 text-center py-5">
          <b-card-text>
            <b-icon icon="exclamation-circle" class="h3" variant="warning" />
            <p class="text-muted">Ainda não há novos valores</p>
          </b-card-text>
        </b-card>
      </template>
    </b-table>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  model: {
    prop: "listValues",
    event: "change",
  },
  props: {
    listValues: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["change"],
  mixins: [validationMixin],
  validations: {
    modelValues: {
      $each: {
        label: {
          required,
        },
        calc: {
          required,
        },
      },
    },
  },
  computed: {
    modelValues: {
      get() {
        return this.listValues;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    fields() {
      return [
        { key: "label", label: "Label do gráfico" },
        { key: "calc", label: "Fórmula", class: "col-auto" },
        { key: "action", label: "", class: "col-1" },
      ];
    },
  },
  methods: {
    addLine() {
      const emptyItem = {
        label: "",
        calc: "",
      };

      if (!this.modelValues && !Array.isArray(this.modelValues)) {
        this.modelValues = [emptyItem];
        return;
      }

      this.modelValues.push(emptyItem);
    },
    deleteLine(index) {
      this.modelValues.splice(index, 1);
    },
    validateState(name, index) {
      const validations = this.$v.modelValues.$each;
      const currentField = validations[index][name];
      const { $dirty, $error } = currentField;
      return $dirty ? !$error : null;
    },
    isValid() {
      this.$v.modelValues.$touch();
      return !this.$v.modelValues.$anyError;
    },
  },
};
</script>
