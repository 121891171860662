import { computed, version } from "vue";

/**
 * @param {*} props
 * @param {string} key
 * @param {*} $emits
 */
export function useVModel(props, key, $emits) {
  const isVue2 = version.slice(0, 4) === "2.7.";
  const event = isVue2 ? "input" : `update:${key}`;

  return computed({
    get: () => props[key],
    set: (value) => $emits(event, value),
  });
}
