<template>
  <div>
    <b-form-group label="Nome:">
      <b-form-textarea
        v-model="objective.name"
        placeholder="Nome"
        class="text-break"
        required
      ></b-form-textarea>
    </b-form-group>

    <b-form-group label="Descrição:">
      <b-form-textarea
        v-model="objective.description"
        placeholder="Descrição"
        wrap="soft"
        class="min-height"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {},
  data: () => ({}),
  computed: {
    ...mapState("objectives", ["objective"])
  },
  methods: {},
  created() {}
};
</script>
<style scoped lang="scss">
.min-height {
  min-height: 120px;
  max-height: 200px;
}
</style>
