<template>
  <div>
    <b-img
      v-for="(ods_item, key) in ods"
      :key="key"
      :src="'https://odsbrasil.gov.br/content/ods/' + ods_item.id + '.png'"
      v-bind="mainProps"
      rounded
      :alt="ods_item.name"
      class="img_ods m-2"
      :class="{ img_not_selected: !is_selected(ods_item.id) }"
      @click="select_ods(ods_item.id)"
    ></b-img>
  </div>
</template>

<script>
import { indexOf } from "lodash";
import { mapState, mapActions } from "vuex";

export default {
  props: {},
  data: () => ({
    mainProps: {
      blankColor: "#777",
      width: 140,
      height: 140,
    },
  }),
  computed: {
    ...mapState("objectives", ["objective", "ods"]),
    selected: {
      get() {
        var response = [];
        this.objective.ods.forEach((ods) => {
          response.push(ods.id);
        });
        return response;
      },
      set(value) {
        this.objective.ods = value;
      },
    },
  },
  methods: {
    ...mapActions("objectives", ["ActionShow", "ActionGetOds"]),
    is_selected(id) {
      return indexOf(this.objective.ods, id) >= 0;
    },
    select_ods(id) {
      if (!this.is_selected(id)) {
        this.objective.ods.push(id);
      } else {
        var index = this.objective.ods.indexOf(id);
        this.objective.ods.splice(index, 1);
      }
    },
  },
  async created() {
    await this.ActionGetOds();
  },
};
</script>

<style scoped>
.img_ods {
  transition: all .3s;
  cursor: pointer;
}
.img_not_selected {
  filter: grayscale(100%);
  opacity: 0.2;
}
</style>
