<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-form-group>
          <b-form-input
            v-model="termo_pesquisa"
            v-on:keyup.enter="loadData"
            placeholder="Buscar indicador ..."
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="3" class="add-button text-primary mb-3">
        <div
          class="d-flex justify-content-center align-items-center"
          @click="show_form_indicator"
        >
          <span class="material-icons mr-2">add</span>
          Novo Indicador
        </div>
      </b-col>
    </b-row>

    <b-table striped hover :items="objective.indicators" :fields="fields">
      <template #cell(actions)="row">
        <div class="d-flex">
          <div class="text-nowrap mr-2">
            <b-iconstack
              font-scale="1"
              variant="warning"
              @click="edit_modal(row.item)"
              class="cursor-pointer"
              v-if="isIndicadorPrimario(row.item)"
            >
              <b-icon stacked icon="pencil"></b-icon>
            </b-iconstack>
          </div>
          <div class="text-nowrap">
            <b-iconstack
              font-scale="1.5"
              variant="danger"
              @click="remove_indicator(row.index)"
              class="cursor-pointer"
            >
              <b-icon stacked icon="circle-fill"></b-icon>
              <b-icon stacked icon="x" variant="white" scale=".6"></b-icon>
            </b-iconstack>
          </div>

          <b-modal
            v-model="showOrClose"
            size="xl"
            id="modal-1"
            title="Editar Indicador"
            :hide-footer="true"
          >
            <IndicatorsEdit
              :idIndicator="idIndicator"
              @close-modal="closeModal"
            />
          </b-modal>
        </div>
      </template>
    </b-table>

    <AddIndicador ref="add_indicador" />
  </div>
</template>

<script>
import IndicatorsEdit from "@/modules/indicators/components/Form.vue";
import AddIndicador from "./AddIndicador.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: { AddIndicador, IndicatorsEdit },
  props: {},
  data: () => ({
    showOrClose: false,
    idIndicator: "",
    termo_pesquisa: ""
  }),
  computed: {
    ...mapState("objectives", ["objective"]),

    fields() {
      return [
        { key: "name", label: "Indicador" },
        { key: "sigla", label: "Sigla" },
        { key: "actions", label: "#" }
      ];
    }
  },
  methods: {
    ...mapActions("objectives", ["ActionFindIndicadores"]),
    ...mapActions("indicators", ["ActionShow", "ActionNew"]),
    show_form_indicator() {
      this.$refs["add_indicador"].show();
    },
    remove_indicator(index) {
      this.objective.indicators.splice(index, 1);
    },
    edit_modal(indicator) {
      this.showOrClose = !this.showOrClose;

      this.ActionNew();
      if (indicator.config && indicator.config["indicador"]) {
        this.ActionShow({ id: indicator.config["indicador"]["id"] });
        //this.idIndicator = ;
        //this.ActionSetIndicator(indicator);
      }
    },
    closeModal() {
      this.showOrClose = false;
    },
    async loadData() {
      try {
        await this.ActionFindIndicadores({
          objective_id: this.$route.params.id,
          q: this.termo_pesquisa
        });
      } catch (error) {
        console.log(error);
      }
    },
    isIndicadorPrimario(indicador) {
      return indicador.config && indicador.config["indicador"];
    }
  },
  async created() {}
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
