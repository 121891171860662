<template>
  <b-dropdown
    v-bind="$attrs"
    :text="currentTitle"
    no-flip
    right
    toggle-class="pl-2"
    variant="outline-primary"
  >
    <b-dropdown-item-button
      v-for="(status, key) of mappedTypes"
      :key="'status_' + key"
      :active="getActiveValue(key)"
      @click="selectValue(key)"
    >
      {{ status }}
    </b-dropdown-item-button>
  </b-dropdown>
</template>
<script setup>
import { computed } from "vue";

import { useStore } from "@/composables/useStore";
import { useVModel } from "@/composables/useVModel";

const props = defineProps({
  value: {
    type: String,
    default: "",
  },
});

const emits = defineEmits(["input"]);

const modelValue = useVModel(props, "value", emits);
const store = useStore();

const mappedTypes = computed(
  () => store.getters["measures/getMappedTypesMeasure"]
);

const currentTitle = computed(() => {
  const key = modelValue.value;
  return mappedTypes.value[key] ?? "Tipo";
});

function selectValue(value) {
  modelValue.value = value;
}

function getActiveValue(value) {
  return String(modelValue.value).includes(value);
}
</script>
<style scoped lang="scss">
::v-deep .dropdown-item {
  &.active {
    background-color: rgba(var(--primary-rgb), 0.1);
    color: var(--primary);
  }
}
</style>
