<template>
  <div size="sm" v-b-toggle="id" @click="expanded = !expanded">
    <span v-if="expanded">
      <b-icon
        class="expander"
        icon="arrow-up-circle"
        style="width: 20px; height: 20px"
      />
    </span>

    <span v-else>
      <b-icon
        class="expander"
        icon="arrow-down-circle"
        style="width: 20px; height: 20px"
      />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
  },
  data: () => ({
    expanded: false,
  }),
};
</script>

<style lang="scss">
.expander {
  fill: #ccc;
  outline: none !important;
  &:hover {
    fill: #335080;
    cursor: pointer;
  }
}
</style>
