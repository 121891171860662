<template>
  <div>
    <b-card-header
      header-tag="header"
      class="px-3 row no-gutters align-items-center card-header"
      role="tab"
      footer-bg-variant="transparent"
    >
      <div class="col pr-2">
        <b-badge v-if="classification" pill class="my-2" rounded>
          {{ typeMeasure }}
        </b-badge>
        <h5>
          {{ name }}
        </h5>
        <p class="small text-secondary" v-if="metricCalc">
          {{ metricCalcLabel }}
        </p>
      </div>

      <div class="col-auto">
        <b-button
          @click="$emit('edit-item')"
          size="sm"
          variant="light"
          class="mr-2 bt-action"
        >
          <font-awesome-icon icon="edit" />
          Editar
        </b-button>
        <b-button
          @click="$emit('delete-item')"
          size="sm"
          variant="light"
          class="mr-2 bt-action text-danger"
        >
          <font-awesome-icon icon="trash" />
          Deletar
        </b-button>
      </div>
    </b-card-header>
    <b-card-body>
      <b-card-text>
        <p>{{ description }}</p>
      </b-card-text>
    </b-card-body>
  </div>
</template>
<script setup>
import { computed } from "vue";

import { useStore } from "@/composables/useStore";

const props = defineProps({
  classification: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  metricCalc: {
    type: String,
    default: "",
  },
});

defineEmits(["edit-item", "delete-item"]);

const store = useStore();

const mappedTypes = computed(
  () => store.getters["measures/getMappedTypesMeasure"]
);

const metricCalcLabel = computed(() => `Fórmula: ${props.metricCalc}`);

const typeMeasure = computed(() => {
  const key = props.classification;
  return mappedTypes.value[key] || "";
});
</script>
