<template>
  <div>
    <b-button
      @click="addLine()"
      variant="outline-primary"
      size="sm"
      class="mb-2"
    >
      <font-awesome-icon icon="plus" /> Nova linha da meta
    </b-button>

    <b-table small :items="limites" :fields="fields">
      <template #cell(label)="{ item }">
        <b-form-input
          v-model="item.label"
          placeholder="Adicione um título, como 'Nova meta'"
          size="sm"
        />
      </template>
      <template #cell(classification)="{ item }">
        <b-form-select
          v-model="item.status_measure"
          :options="classifications"
          size="sm"
        />
      </template>
      <template #cell(operador)="{ item }">
        <b-form-select
          v-model="item.operador"
          :options="operadores"
          size="sm"
        />
      </template>
      <template #cell(cor)="{ item }">
        <b-form-input
          v-model="item.cor"
          type="color"
          placeholder="Cor"
          size="sm"
        />
      </template>

      <template #cell(actions)="{ item, index }">
        <b-button
          @click="deleteLine(item, index)"
          variant="outline-danger"
          size="sm"
        >
          <font-awesome-icon icon="trash-alt" />
        </b-button>
      </template>
    </b-table>

    <b-button
      @click="addDeadline()"
      variant="outline-primary"
      size="sm"
      class="mb-2"
    >
      <font-awesome-icon icon="plus" /> Novo Limite
    </b-button>

    <b-table-simple small responsive hover class="table-valores">
      <b-thead>
        <b-tr>
          <b-th class="col-date">Prazo</b-th>
          <b-th class="col-date">Referência</b-th>
          <b-th v-for="(limite, key) in limites" :key="key" class="text-center">
            <font-awesome-icon
              icon="square"
              :style="{ color: limite.cor, fontSize: '20px' }"
            />
          </b-th>
          <b-th class="col-delete">#</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(target, key) in measure.targets" :key="'target-' + key">
          <b-td>
            <date-input v-model="target.deadline" size="sm" />
          </b-td>
          <b-td>
            <date-input v-model="target.reference_date" size="sm" />
          </b-td>
          <b-td
            v-for="(limite, key_limite) in limites"
            :key="'limite-' + key_limite"
          >
            <b-form-input
              v-model="measure.targets[key].value[key_limite].metric"
              placeholder="Fórmula"
              size="sm"
            />
          </b-td>
          <b-td>
            <b-button
              @click="deleteDeadline(key)"
              variant="outline-danger"
              size="sm"
            >
              <font-awesome-icon icon="trash-alt" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    limites: [],
    valores: [],
  }),
  computed: {
    ...mapState("measures", ["measure"]),
    fields() {
      return [
        { key: "label", label: "Label", class: "col" },
        { key: "classification", label: "Classificação", class: "col-auto" },
        { key: "operador", label: "Operador", class: "col-1" },
        { key: "cor", label: "Cor", class: "col-1" },
        { key: "actions", label: "", class: "col-1" },
      ];
    },

    classifications() {
      return [
        { value: null, text: "--" },
        { value: "goal", text: "Atingida" },
        { value: "alert", text: "Alerta" },
        { value: "critic", text: "Não Atingida" },
      ];
    },

    operadores() {
      return [
        { value: "==", text: "=" },
        { value: ">", text: ">" },
        { value: ">=", text: ">=" },
        { value: "<", text: "<" },
        { value: "<=", text: "<=" },
      ];
    },
  },
  methods: {
    setLimites() {
      let limites = [];
      let addLabel = [];

      this.measure.targets.forEach((target) => {
        target.value.forEach((value) => {
          if (addLabel.indexOf(value.label) == -1) {
            limites.push({
              label: value.label,
              operador: value.operator,
              cor: value.color,
              status_measure: value.status_measure,
            });
            addLabel.push(value.label);
          }
        });
      });
      this.limites = limites;
    },
    addLine() {
      this.limites.push({
        label: "",
        operador: "",
        status_measure: null,
        cor: "#000000",
      });
    },
    deleteLine(item, index) {
      this.measure.targets.forEach((target) => {
        target.value.splice(index, 1);
      });

      this.limites.splice(index, 1);
    },
    addDeadline() {
      const value = [];
      const limites = (this.limites || []).slice();

      for (const limite of limites) {
        value.push({
          color: limite.cor,
          label: limite.label,
          metric: null,
          operator: limite.operador,
        });
      }

      this.measure.targets.push({
        deadline: "",
        reference_date: "",
        value: value,
      });
    },
    deleteDeadline(index) {
      this.measure.targets.splice(index, 1);
    },
  },
  watch: {
    limites: {
      deep: true,
      handler: function (value) {
        const num_values = value.length;

        this.measure.targets.forEach((target, index) => {
          for (let i = 0; i < num_values; i++) {
            if (target.value[i]) {
              const new_value = {
                label: value[i].label,
                color: value[i].cor,
                operator: value[i].operador,
                status_measure: value[i].status_measure,
                metric: target.value[i].metric,
              };

              this.measure.targets[index].value[i] = new_value;
            } else {
              this.measure.targets[index].value.push({
                color: value[i].cor,
                label: value[i].label,
                metric: null,
                status_measure: value[i].status_measure,
                operator: value[i].operador,
              });
            }
          }
        });
      },
    },
  },
  mounted() {
    this.setLimites();
  },
};
</script>

<style lang="scss">
.table-valores {
  td {
    padding: 0 !important;
  }

  .col-date {
    min-width: 150px;
  }
}
</style>
